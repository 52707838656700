import RootStore from './rootStore';
import { observable, action, reaction, runInAction } from 'mobx';
import agent from '../api/agent';
import { IUser } from '../models/user';
export default class CommonStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem('jwt', token);
                } else {
                    window.localStorage.removeItem('jwt')
                }
            }
        )
    }

    @observable token: string | null = window.localStorage.getItem('jwt');
    @observable user: IUser | null = null;
    @observable loader: boolean = false;
    @observable isFirstLoad: boolean = true;
    @action setToken = (user: any | null) => {
        runInAction(() => {
            this.token = user.accessToken;
            if (user.accessToken) {
                const minutesToAdd = 12;
                const currentDate = new Date();
                const futureDate: any = new Date(currentDate.getTime() + minutesToAdd * 60000);
                window.localStorage.setItem('refreshToken', user?.refreshToken);
                window.localStorage.setItem('expires', user?.expires.toString());
                window.localStorage.setItem('tokenExpiryTime', futureDate);
                if (this.isFirstLoad) {
                    this.isFirstLoad = false
                }
            }
        });
    }
    getDifferenceInMinutes(date1: any, date2: any) {
        const diffInMs = Math.abs(date2 - date1);
        return diffInMs / (1000 * 60);
    }
    @action setLoader = (loading: boolean) => {
        this.loader = loading;
    }
    @action checkTokenExpiry = (isWait: boolean) => {
        const token = localStorage.getItem('jwt');
        if (token) {
            if (isWait) {
                const currentDate = new Date();
                const expiryTime: any = localStorage.getItem('tokenExpiryTime');
                this.getDifferenceInMinutes(currentDate, new Date(expiryTime))
                this.refreshToken()
            } else {
                this.refreshToken()
            }
        } else {
            window.location.hash = '/login';
        }
    }
    @action refreshToken = async () => {
        try {
            const refreshToken = localStorage.getItem('refreshToken' || null)
            if (refreshToken) {
                const payLoad = {
                    jwtToken: localStorage.getItem('jwt'),
                    refreshToken: localStorage.getItem('refreshToken')
                }
                const user = await agent.User.refreshToken(payLoad);
                console.log('refresh token call', new Date())
                this.rootStore.commonStore.setToken(user);
                runInAction(() => {
                    this.user = user;
                    const isLanguage = localStorage.getItem('languageId' || null)
                    if (!isLanguage) {
                        this.rootStore.masterStore.actionLanguageMaster()
                    }
                });

                if (window.location.href.indexOf('/login') > -1) {
                    window.location.hash = '/';
                }
            } else {
                window.location.hash = '/login';
            }
        } catch (error) {
            window.location.hash = '/login';
            throw error;
        }
    };
}