const catalogPrefix = process.env.REACT_APP_CATALOG_SERVICE;
const customerPrefix = process.env.REACT_APP_CUSTOMER_SERVICE;
const orderPrefix = process.env.REACT_APP_ORDER_SERVICE;
const identityPrefix = process.env.REACT_APP_IDENTITY_SERVICE;
const env = process.env.REACT_APP_ENV;
export const config = {
  apiBaseUrl: process.env.REACT_APP_API_URL,
  env: process.env.REACT_APP_API_URL,
  apiUrls: {
    catalogService: {
      getAllProducts: `${catalogPrefix}/product/page/{page}/page-size/{pageSize}/category/-1/products`,
      bulkProductUpload: `${catalogPrefix}/allproduct/save`,
      fetchIndProduct: `${catalogPrefix}/product/`,
      productBannerList: `${catalogPrefix}/banner/banners`,
      productBannerDetail: `${catalogPrefix}/banner/{id}`,
      saveProductBanner: `${catalogPrefix}/banner/save`,
      bannerListWithPaging: `${catalogPrefix}/banner/page/{page}/page-size/{pagesize}/banners`,
      getAllSettings: `${catalogPrefix}/settings`,
      getSetting: `${catalogPrefix}/setting/get/{key}`,
      saveSettings: `${catalogPrefix}/setting/save`,
      getResellerbanner: `${catalogPrefix}/resellerbanner/banners`,
      getCagegories: `${catalogPrefix}/category/categories`,
      saveCagegories: `${catalogPrefix}/category/save`,
      saveCagegoriImage: `${catalogPrefix}/category/picture/`,
      getCagegoriId: `${catalogPrefix}/category/`,
      saveProduct: `${catalogPrefix}/product/save`,
      saveProductImage: `${catalogPrefix}/product/picture/`,
      productTags: `${catalogPrefix}/searchtag/`,
      saveBandMaster: `${catalogPrefix}/band/add`,
      getBandMaster: `${catalogPrefix}/allbands`,
      getLanguageMaster: `${catalogPrefix}/languages`,
      getGeoData: `${catalogPrefix}/countries`,
      pagedMainbannerList: `${catalogPrefix}/mainbanner/page/`,
      pagedMainbannerSave: `${catalogPrefix}/mainbanner/save`,

    },
    customerService: {
      agentBand: `${customerPrefix}/agent/band`,
      createAgentBand: `${customerPrefix}/agent/band/save`,
      internalUsers: `${customerPrefix}/internalusers`,
      pagedAgent: `${customerPrefix}/page/`,
      updateAgent: `${env === 'DEV' ? `/agent/save` : `${customerPrefix}/agent/save`}`,
      fcmusers: `${customerPrefix}/fcmusers/`,
      internaluserSave: `/internaluser/save`,
      customerSave: `${customerPrefix}/save`,
      getAllAgents: `${customerPrefix}/agents`,
      customerBandUpdate: `${customerPrefix}/band/customerupdate`,
      walletSave: `${customerPrefix}/wallet/save`,
      getCustomerWallet: `${customerPrefix}/customer-Id/{customerId}/wallet`,
      getCustomerWaletHistory: `${customerPrefix}/page/{page}/page-size/{pagesize}/wallethistory`,
      pushSend: `${customerPrefix}/push/send`,
      resellerKycUpdate: `${customerPrefix}/reseller/kyc/{id}/update`,
      resellerKycVerify: `${customerPrefix}/reseller/kyc/{id}/verify`,
      createResellerBanner: `/resellerbanner/save`,
      pagedCustomer: `${customerPrefix}/page/`,
      customerWishlist: `${customerPrefix}/customer-id/`,
      agentReport: `${customerPrefix}/report/agents`,
      kycVerify: `${customerPrefix}/customer/kyc/{id}/verify`,
      customersWeekReport: `${customerPrefix}/customers/week/report`,
      customerDetail: `${customerPrefix}/customer-id/{customerId}/customer`,
      customerAccountUpdate: `${customerPrefix}/outstanding/save`,

    },
    orderService: {
      discountcoupan: `${orderPrefix}/discountcoupan`,
      discountcoupan_coupan: `${orderPrefix}/discountcoupan/{coupan}`,
      preorder_page_size: `${orderPrefix}/preorder/page/`,
      AssingedDeliveryAgent: `${orderPrefix}/assingeddeliveryagent`,
      orderStatusUpdate: `${orderPrefix}/order/status/update`,
      orderUpdateQty: `${orderPrefix}/order/update/quantity`,
      getOrderDetail: `${orderPrefix}/order/`,
      updateDeliveryDate: `${orderPrefix}/order/update/delivery/date`,
      productReports: `${orderPrefix}/productreports/`,
      salesReport: `${orderPrefix}/salesreport/`,
      lastWeekOrders: `${orderPrefix}${env === 'DEV' ? '/orders/lastweek' : '/report/lastweek'}`,
      topsale: `${orderPrefix}${env === 'DEV' ? '/orders/topsale' : '/report/topsale'}`,
    },
    identityService: {
      login: `${identityPrefix}/sign-in`,
      mobile_sign_in: `${identityPrefix}/mobile-sign-in`,
      portal_sign_in: `${identityPrefix}/portal/signin`,
      addAgent: `${identityPrefix}/addagent`,
      getrole: `${identityPrefix}/getrole`,
      internLUserChangePassword: `${identityPrefix}/change/password`,
      addinternalUser: `${identityPrefix}/addinternaluser`,
      refresh_tokens_use: `${identityPrefix}/refresh-tokens/use`,
      addmember: `/identity/addmember`,
      pagedUser: `${identityPrefix}/page/`
    },
  },
  images: {
    kycImages: `${process.env.REACT_APP_IMAGE_URL}/customer/kyc/`,
    productThumbnail: `${process.env.REACT_APP_IMAGE_URL}/products/thumbnail/`,
  },
  ports: {
    default: process.env.REACT_APP_API_PORT_DEFAULT,
    identityService: process.env.REACT_APP_API_PORT_IDENTITY_SERVICE,
    catalogService: process.env.REACT_APP_API_PORT_CATALOG_SERVICE,
    customerservice: process.env.REACT_APP_API_PORT_CUSTOMER_SERVICE,
    orderService: process.env.REACT_APP_API_PORT_ORDER_SERVICE,
    apiGateway: process.env.REACT_APP_API_PORT_APIGATEWAY,
  },
};
