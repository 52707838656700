import React, { Suspense, lazy, useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Main from "./Main";
import "./App.css";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../stores/rootStore";
import LoadingComponent from "./LoadingComponent";

const LoginContainer = lazy(
  () => import("../../features/login/LoginContainer")
);
const RegisterContainer = lazy(
  () => import("../../features/register/RegisterContainer")
);
const ForgotPasswordContainer = lazy(
  () => import("../../features/forgot-password/ForgotPasswordContainer")
);

const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const rootStore = React.useContext(RootStoreContext);
  const {
    languageMaster,
    setActiveLanguage,
    actionLanguageMaster
  } = rootStore.masterStore;
  const { checkTokenExpiry, isFirstLoad } = rootStore.commonStore;
  useEffect(() => {
    const jwt = localStorage.getItem("jwt") || null;
    if (jwt) {
      checkTokenExpiry(false);
    }
  }, []);
  useEffect(()=>{
    if(!isFirstLoad){
      actionLanguageMaster()
    }
  },[isFirstLoad])
  useEffect(() => {
    const jwt = localStorage.getItem("jwt") || null;
    if (jwt && languageMaster && languageMaster.length > 0) {
      languageMaster.map((item) => {
        if (item.isDefault) {
          localStorage.setItem('languageId', item?.id)
          setActiveLanguage(item)
        }
      })
    }
  }, [languageMaster])
  setInterval(function () {
    const jwt = localStorage.getItem("jwt") || null;
    if (jwt) {
      checkTokenExpiry(true);
    }
  }, 12 * 60 * 1000);
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("jwt") ? <>
          {isFirstLoad?'loading': <Component {...props} {...rest} />}
        </> : (
          <Redirect
            to={{
              pathname: `/login`,
              state: { referrer: props.location },
            }}
          />
        )
      }
    />
  )
}

function App() {
  const rootStore = React.useContext(RootStoreContext);
  const { checkTokenExpiry, isFirstLoad } = rootStore.commonStore;
  return (
    <Suspense fallback={`...loading`}>
      <LoadingComponent />
      <Switch>
        <Route path={`/login`} component={LoginContainer} />
        <Route path={`/register`} component={RegisterContainer} />
        <Route path={`/forgot-password`} component={ForgotPasswordContainer} />
        <ProtectedRoute path="/" component={Main} />
      </Switch>
    </Suspense>
  );
}

export default observer(App);
