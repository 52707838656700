import React from "react";
import { Link } from "react-router-dom";
interface IProps { }
const SideNavComponent: React.FC<IProps> = (props) => {
  const [toggleCss, settoggleCssSys] = React.useState<any>(null);
  const _toggleNavSys = (key: any) => {
    let tab: any = toggleCss || {};
    tab[key] = !tab[key];
    settoggleCssSys({ ...tab });
  };
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <Link to="/" className="brand-link">
        <span className="brand-text font-weight-light">Suri</span>
      </Link>
      <div className="sidebar">
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column nav-child-indent nav-flat">
            <li className="nav-item menu-open">
              <Link to="/" className={window.location.hash === "#/" || window.location.hash === "#/" ? "nav-link active"
                : "nav-link"}>
                <i className="nav-icon fas fa-home"></i>
                <p>Home</p>
              </Link>
            </li>
            <li
              className="nav-item has-treeview"
              onClick={() => _toggleNavSys("category")}
            >
              <a className={window.location.href.indexOf('/category/') > -1 || window.location.href.indexOf('/product/') > -1 ? "nav-link active" : "nav-link"}>
                <i className="nav-icon fa fa-book"></i>
                <p>
                  Catalog
                  <i
                    className={`right fas fa-angle-${(window.location.href.indexOf('/category/') > -1 || window.location.href.indexOf('/product/') > -1) || toggleCss && toggleCss["category"] ? "up" : "right"
                  }`}></i>
                </p>
              </a>
              <ul
                className={(window.location.href.indexOf('/category/') > -1 || window.location.href.indexOf('/product/') > -1) ||
                toggleCss && toggleCss["category"]
                ? "nav nav-treeview d-block"
                : "nav nav-treeview"
              }
              >
                <li className="nav-item">
                  <Link className="nav-link" to="/category/list">
                    <i
                      className={
                        window.location.href.indexOf('/category/list') > -1
                          ? "fa fa-circle nav-icon"
                          : "far fa-circle nav-icon"
                      }
                    ></i>
                    <p>Category</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/product/list">
                    <i
                      className={
                        window.location.href.indexOf('/product/list') > -1
                          ? "fa fa-circle nav-icon"
                          : "far fa-circle nav-icon"
                      }
                    ></i>
                    <p>Product</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item has-treeview">
              <Link className={window.location.href.indexOf('/customer/') > -1 ? "nav-link active" : "nav-link"} to="/customer/list">
                <i className="nav-icon fas fa-user"></i>
                <p>Customer</p>
              </Link>
            </li>
            <li className="nav-item has-treeview">
              <Link className={window.location.href.indexOf("/agent/") > -1 ? "nav-link active" : "nav-link"} to="/agent/list">
                <i className="nav-icon fas fa-user"></i>
                <p>Agent</p>
              </Link>
            </li>
            <li className="nav-item has-treeview">
              <Link className={window.location.href.indexOf("/orders") > -1 ? "nav-link active" : "nav-link"} to="/orders">
                <i className="nav-icon fas fa-clipboard-list"></i>
                <p>Order</p>
              </Link>
            </li>
            <li className="nav-item has-treeview">
              <Link className={window.location.href.indexOf("/preorder") > -1 ? "nav-link active" : "nav-link"} to="/preorder/list">
                <i className="nav-icon fas fa-clipboard-list"></i>
                <p>Pre Order</p>
              </Link>
            </li>
            <li className="nav-item has-treeview">
              <Link className={window.location.href.indexOf("/reports") > -1 ? "nav-link active" : "nav-link"} to="/reports">
                <i className="nav-icon fas fa-clipboard-list"></i>
                <p>Reports</p>
              </Link>
            </li>
            <li
              className="nav-item has-treeview"
              onClick={() => _toggleNavSys("system")}
            >
              <a className={window.location.href.indexOf('/users/internalUser') > -1 ? "nav-link active" : "nav-link"}>
                <i className="nav-icon fas fa-cube"></i>
                <p>
                  System
                  <i
                    className={`right fas fa-angle-${(window.location.href.indexOf('/users/internalUser') > -1) || toggleCss && toggleCss["system"] ? "up" : "right"
                  }`}
                  ></i>
                </p>
              </a>
              <ul
                className={(window.location.href.indexOf('/users/internalUser') > -1) ||
                toggleCss && toggleCss["system"]
                ? "nav nav-treeview d-block"
                : "nav nav-treeview"
              }
              >
                <li className="nav-item">
                  <Link className="nav-link" to="/users/internalUser">
                    <i
                      className={
                        window.location.href.indexOf("/users/internalUser") > -1
                          ? "fa fa-circle nav-icon"
                          : "far fa-circle nav-icon"
                      }
                    ></i>
                    <p>Internal Users</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className="nav-item has-treeview"
              onClick={() => _toggleNavSys("promotion")}
            >
              <a className={window.location.href.indexOf("/banners") > -1 || window.location.href.indexOf("/product-banners") > -1 || window.location.href.indexOf("/push/send") > -1 || window.location.href.indexOf('/coupon') > -1 ? "nav-link active" : "nav-link"}>
                <i className="nav-icon fas fa-tags"></i>
                <p>
                  Promotion
                  <i
                    className={`right fas fa-angle-${window.location.href.indexOf("/banners") > -1 || (window.location.href.indexOf("/product-banners") > -1 || window.location.href.indexOf("/push/send") > -1 || window.location.href.indexOf('/coupon') > -1) || toggleCss && toggleCss["promotion"] ? "up" : "right"
                  }`}
                  ></i>
                </p>
              </a>
              <ul
                className={(window.location.href.indexOf("/banners") > -1 || window.location.href.indexOf("/product-banners") > -1 || window.location.href.indexOf("/push/send") > -1 || window.location.href.indexOf('/coupon') > -1) ||
                toggleCss && toggleCss["promotion"]
                ? "nav nav-treeview d-block"
                : "nav nav-treeview"
              }
              >
                <li className="nav-item">
                  <Link className="nav-link" to="/banners/main/list">
                    <i
                      className={
                        window.location.href.indexOf("/banners/main") > -1
                          ? "fa fa-circle nav-icon"
                          : "far fa-circle nav-icon"
                      }
                    ></i>
                    <p>Main Banner</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/product-banners">
                    <i
                      className={
                        window.location.href.indexOf("/product-banners") > -1
                          ? "fa fa-circle nav-icon"
                          : "far fa-circle nav-icon"
                      }
                    ></i>
                    <p>Product Banners</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/push/send">
                    <i
                      className={
                        window.location.href.indexOf("/push/send") > -1
                          ? "fa fa-circle nav-icon"
                          : "far fa-circle nav-icon"
                      }
                    ></i>
                    <p>Push Notification</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/coupon/list">
                    <i
                      className={
                        window.location.href.indexOf("/coupon/list") > -1
                          ? "fa fa-circle nav-icon"
                          : "far fa-circle nav-icon"
                      }
                    ></i>
                    <p>Coupons</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className="nav-item has-treeview"
              onClick={() => _toggleNavSys("master")}
            >
              <a className={window.location.href.indexOf('/master/') > -1 ? "nav-link active" : "nav-link"}>
                <i className="nav-icon fas fa-cog"></i>
                <p>
                  Master
                  <i
                    className={`right fas fa-angle-${(window.location.href.indexOf('/master/') > -1) || toggleCss && toggleCss["master"] ? "up" : "right"
                  }`}
                  ></i>
                </p>
              </a>
              <ul
                className={
                  (window.location.href.indexOf('/master/') > -1) || toggleCss && toggleCss["master"]
                    ? "nav nav-treeview d-block"
                    : "nav nav-treeview"
                }
              >
                <li className="nav-item">
                  <Link className="nav-link" to="/master/settings">
                    <i
                      className={
                        window.location.href.indexOf("/master/settings") > -1
                          ? "fa fa-circle nav-icon"
                          : "far fa-circle nav-icon"
                      }
                    ></i>
                    <p>Settings</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/master/roles">
                    <i
                      className={
                        window.location.href.indexOf("/master/roles") > -1
                          ? "fa fa-circle nav-icon"
                          : "far fa-circle nav-icon"
                      }
                    ></i>
                    <p>Roles</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/master/band">
                    <i
                      className={
                        window.location.href.indexOf("/master/band") > -1
                          ? "fa fa-circle nav-icon"
                          : "far fa-circle nav-icon"
                      }
                    ></i>
                    <p>Band</p>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};
export default SideNavComponent;
