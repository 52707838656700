import RootStore from "./rootStore";
import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { IBarGraph, IDataset, IHeaderStatics, IOrderData } from "../models/dashboard";

export default class DashboardStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable loader: boolean = false;
  @observable weeklyOrder: IOrderData[] = [];
  @observable weekData: IBarGraph | null = null;
  @observable orderChartData: any | null = null;
  @observable customerChartData: any | null = null;
  @observable dashboardStatics : IHeaderStatics | null = null;

  @action getOrderWeeklyReport = async () => {
    const result = await agent.Dashboard.getWeeklyOrder();
    const xxxx: IDataset = {
        label: "Orders",
        backgroundColor: "rgba(255,99,132,0.2)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [65],
    };

    const data: IBarGraph = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'Orders',
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [65, 59, 80, 81, 56, 55, 40]
          }
        ]
      };

    try {
      runInAction("loading customer", () => {
        this.weeklyOrder = result;
        this.weekData = data;
      });
    } catch (error) {}
  };

  @action getHeaderStatics = async () => {
    try {
      const result = await agent.Dashboard.getStatics();
      runInAction("loading statics", () => {
        this.dashboardStatics = result;
      });
    } catch (error) {
      console.error(error);
    }
  }

  @action getOrderChart = async () => {
    try {
      const result = await agent.Dashboard.getOrderChart();
      runInAction("loading statics", () => {
        this.orderChartData = result;
      });
    } catch (error) {
      console.error(error);
    }
  }
  @action getCustomerChart = async () => {
    try {
      const result = await agent.Dashboard.getCustomerChart();
      runInAction("loading statics", () => {
        this.customerChartData = result;
      });
    } catch (error) {
      console.error(error);
    }
  }
  
}
