import { observable, action, runInAction } from "mobx";
import RootStore from "./rootStore";
import agent from "../api/agent";
import { ICategory, ICategorySearch, IPagedCategory } from "../models/category";
import { toast } from "react-toastify";
export default class CategoryStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable pagedCategories: IPagedCategory | null = null;
  @observable category: ICategory | null = null;
  @observable categories: ICategory[] = [];
  @observable edited: boolean = false;
  @observable editCategoryId: string = '';
  @action loadCategories = async (
    page: number,
    pageSize: number,
    param: ICategorySearch
  ) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const categories = await agent.Category.getPagedCategories(
        page,
        pageSize,
        param
      );
      runInAction("loading categories", () => {
        this.pagedCategories = categories;
      });
      this.rootStore.commonStore.setLoader(false);
    } catch (error) {
      console.log("Error is ", error);
      this.rootStore.commonStore.setLoader(false);
    }
  };
  @action allCategories = async () => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const categories = await agent.Category.getCategories();
      runInAction("loading categories", () => {
        this.categories = categories;
      });
      this.rootStore.commonStore.setLoader(false);
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
    }
  };
  @action uploadPhoto = async (id: string, file: Blob) => {
    this.rootStore.commonStore.setLoader(true);
    try {
      const photo = await agent.Category.uploadPhoto(id, file);
      runInAction(() => {
        this.rootStore.commonStore.setLoader(false);
        toast.success(photo.messages[0]);
      });
    } catch (error) {
      console.log(error);
      toast.error("Problem uploading photo");
      this.rootStore.commonStore.setLoader(false);
    }
  };
  @action addCategory = async (category: ICategory) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const result = await agent.Category.addEditCategory(category);
      runInAction("category saved", () => {
        toast.success(result.messages[0]);
      });
      this.category = category;
      if (this.edited) {
        this.editCategoryId = "";
      }
      this.rootStore.commonStore.setLoader(false);
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
    }
  };
  @action addCategoryImage = async (id: any, payload: any) => {
    try {
      let data = {
        FileContent: payload.replace("data:image/jpeg;base64,", ""),
        ImageName: 'test.jpg',
        Id: id
      }
      const result = await agent.Category.addCategoryImage(id, data);
      runInAction("category saved", () => {
        toast.success(result.messages[0]);
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      toast.success("Error while uploading category image");
      console.log(error)
    }
  };
  @action setSelectedCategory = (category: ICategory) => {
    console.log(category)
    this.category = JSON.parse(JSON.stringify(category));
  };
  @action clearCategory = () => {
    this.category = null;
  };
  @action editSelected = (isEdited: boolean, id: string) => {
    this.edited = isEdited;
    this.editCategoryId = id;
  };
  @action getCategoryById = async (id: string, isLanguageChange:boolean) => {
    try {
      if (this.category === null || isLanguageChange) {
        this.rootStore.commonStore.setLoader(true);
        const result = await agent.Category.getCategoryById(id);
        runInAction(() => {
          this.category = result;
        });
        this.rootStore.commonStore.setLoader(false);
      }
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
    }
  }
}
