import { observable, action, runInAction } from "mobx";
import RootStore from "./rootStore";
import agent from "../api/agent";
import { ICustomer, IPagedCustomer, ICustomerSearch } from "../models/customer";
import { toast } from "react-toastify";
export default class CustomerStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable pagedCustomer: IPagedCustomer | null = null;
  @observable customers: ICustomer[] = [];
  @observable customer: ICustomer | null = null;
  @observable edited: boolean = false;
  @observable pagedCartData: any | null = null;
  @observable customerCartWishlistData: any | null = null;
  @observable customerWalletData: any | null = null;
  @observable customerWalletHistoryData: any | null = null;
  @observable userList: any | [] = [];
  @observable fcmUserList: any | [] = [];
  @observable customersData: any = [];
  @observable AgentMasterList: any | null = null;
  @observable updateCustomerAction: boolean | null = null;
  @observable customerDetail: any | null = null;

  @action loadPagedCustomer = async (
    page: number,
    pageSize: number,
    param: ICustomerSearch
  ) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const customers = await agent.Customer.getPagedCustomer(
        page,
        pageSize,
        param
      );
      console.log(customers);
      runInAction("loading customer", () => {
        this.pagedCustomer = customers;
      });
      this.rootStore.commonStore.setLoader(false);
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
    }
  };
  @action setSelectedCustomer = (customer: ICustomer) => {
    this.customer = customer;
  };
  @action clearCategory = () => {
    this.customer = null;
  };
  @action editSelected = (isEdited: boolean) => {
    this.edited = isEdited;
  };
  @action loadPagedWishlist = async (
    page: number,
    pageSize: number,
    param: any,
    pageType: string
  ) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      let list: any = [];
      if (pageType === 'wishlists') {
        list = await agent.Customer.getPagedWishList(
          page,
          pageSize,
          param
        );
      } else if (pageType === 'carts') {
        list = await agent.Customer.getPagedCart(
          page,
          pageSize,
          param
        );
      }
      runInAction("loading customer", () => {
        this.pagedCartData = list;
      });
      this.rootStore.commonStore.setLoader(false);
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
    }
  };
  @action customerCartAndWishlist = async (
    customerId: string,
    pageType: string
  ) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      let list: any = [];
      list = await agent.Customer.getCustomerCartWishlist(
        customerId, pageType
      );
      runInAction(`loading customer ${pageType}`, () => {
        this.customerCartWishlistData = list;
      });
      this.rootStore.commonStore.setLoader(false);
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
    }
  };
  @action getCustomerWallet = async (customerId: string) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      let list: any = {};
      list = await agent.Customer.getCustomerWallet(
        customerId
      );
      runInAction("loading customer", () => {
        this.customerWalletData = list;
      });
      this.rootStore.commonStore.setLoader(false);
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
    }
  };
  @action refundInWallet = async (...args: any) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const response = await agent.Customer.customerWalletSave(args[0])
      console.log("wallet save response ", response);
      runInAction("saving Wallet", () => {
        if (args[1]) {
          this.rootStore.orderStore.updateRefundOrderStatus(args[1].id, args[1])
        }
        this.rootStore.commonStore.setLoader(false);
        toast.success("Refund initiated")
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      toast.error("Error while saving Reseller");
      console.log("Error : ", error);
    }
  };

  @action getCustomerWalletHistory = async (page: any, pagesize: any, param: any) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      let list: any = {};
      list = await agent.Customer.getCustomerWalletHistory(
        page,
        pagesize,
        param
      );
      runInAction("loading customer", () => {
        this.customerWalletHistoryData = list;
      });
      this.rootStore.commonStore.setLoader(false);
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
    }
  };
  @action PushSend = async (payload: any) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      let list: any = {};
      list = await agent.Customer.PushSend(payload);
      runInAction("loading customer", () => {
        this.customerWalletHistoryData = list;
      });
      this.rootStore.commonStore.setLoader(false);
      toast.success("Push Notification send")
    } catch (error) {
      const err: any = error;
      this.rootStore.commonStore.setLoader(false);
      toast.error((err.data && err.data.messages && err.data.messages.length && err.data.messages[0]) || "Error while sending push Notification");
    }
  };
  @action getFcmUserList = async (
    name: string,
    type: string,
  ) => {
    try {
      const data = await agent.Customer.getAllUsers(
        name,
        type,
      );
      runInAction("loading customer", () => {
        this.userList = data || [];
        this.fcmUserList = data || [];
        // this.fcmUserList = this.customersData;
      });
    } catch (error) {
    }
  };
  @action setUserList = async (data: any) => {
    try {
      this.fcmUserList = data || [];
    } catch (error) {
    }
  };

  @action actionAgentCustomerMapping = async (
    customerId: string,
    agentid: string,
  ) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      await agent.Customer.customerAgentMapping(customerId, agentid);
      runInAction("updating customer agent", () => {
        this.rootStore.commonStore.setLoader(false);
        this.updateCustomerAction = true;
        // const data = {
        //   customerId: customerId,
        //   agentId: agentid
        // }
        // this.customerAgentDataUpdate(data)
        toast.success('Customer Agent updated successfully')
      });
    } catch (error) {
      const err: any = error
      this.rootStore.commonStore.setLoader(false);
      toast.error(err.data.reason || 'Error while updating customer Agents')
      console.log("Error : ", error);
    }
  };
  @action actionLoadAgents = async () => {
    try {
      const list = await agent.Customer.getAllAgents();
      runInAction("loading agent", () => {
        this.AgentMasterList = list;
      });
    } catch (error) {
    }
  };
  @action setCustomerAction = async () => {
    try {
      this.updateCustomerAction = false
    } catch (error) {
    }
  };
  @action customerBandDataUpdate = async (data: any) => {
    let list: any = { ...this.pagedCustomer }
    list?.customers.map((item: any, index: number) => {
      if (item?.id === data?.customerId) {
        item.bandId = data.bandId
      }
    })
  }
  @action customerAgentDataUpdate = async (data: any) => {
    let list: any = { ...this.pagedCustomer }
    list?.customers.map((item: any, index: number) => {
      if (item?.id === data?.customerId) {
        item.agentId = data.agentId
      }
    })
  }
  @action customerBandMapping = async (
    payload: any,
  ) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      await agent.Customer.customerBandMapping(payload);
      runInAction("updating customer band", () => {
        this.rootStore.commonStore.setLoader(false);
        this.updateCustomerAction = true;
        toast.success('Customer band updated successfully');
        // this.customerBandDataUpdate(payload)
      });
    } catch (error) {
      const err: any = error
      this.rootStore.commonStore.setLoader(false);
      toast.error(err.data.reason || 'Error while updating customer bands')
    }
  };
  @action VerifyKyc = async (id: string, data: any) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      await agent.Customer.KycVerify(id, data)
      runInAction("Vefifying KYC", () => {
        this.rootStore.commonStore.setLoader(false);
        if (data?.IsKycCompleted) {
          toast.success("KYC Verified Successfully");
        } else {
          toast.success("Kyc Rejected Successfully");
        }
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      toast.error("Error while Vefifying Kyc");
      console.log("Error : ", error);
    }
  };
  @action actionCustomerDetail = async (id: string, isEdit: boolean) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const res: any = await agent.Customer.CustomerDetail(id)
      runInAction("Getting Customer Detail", () => {
        this.customerDetail = res;
        this.rootStore.commonStore.setLoader(false);
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      toast.error("Getting Customer Detail");
      console.log("Error : ", error);
    }
  };
  @action updateCusomerDetail = async (values: any) => {
    try {
      const data ={
        ...this.customerDetail
      }
      data.customerAddressDto.city='';
      data.customerAddressDto.stateAddress=values.stateAddress;
      console.log(JSON.parse(JSON.stringify(data)));
      this.setCustomerDetail(JSON.parse(JSON.stringify(data)));
    } catch (error) {

    }
  };
  @action setCustomerDetail = async (values: any) => {
    try {
      runInAction("Edit Customer Detail", () => {
        this.customerDetail = values;
      });
    } catch (error) {

    }
  };
  @action EditCustomer = async (values: any, message: string) => {
    try {
      await agent.Customer.EditCustomer(values);
      runInAction("Edit Customer Detail", () => {
        this.rootStore.commonStore.setLoader(false);
        toast.success(message)
      });
    } catch (error) {
      const err: any = error
      toast.error(err.data.reason || message + ' Error')
      console.log("Error is ", error);
    }
  };
  @action CustomerAccountUpdate = async (values: any, message: string) => {
    try {
      await agent.Customer.CustomerAccountUpdate(values);
      runInAction("Edit Customer Detail", () => {
        this.rootStore.commonStore.setLoader(false);
        toast.success(message)
      });
    } catch (error) {
      const err: any = error
      toast.error(err.data.reason || message + ' Error')
      console.log("Error is ", error);
    }
  };
}
