import { observable, computed, action, runInAction } from 'mobx';
import { IPagedUser, IPInternalUsers, IPRoleMastr, IUser, IUserLogin, IUserSearch } from '../models/user';
import RootStore from './rootStore';
import agent from '../api/agent';
import { toast } from "react-toastify";
export default class UserStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable pagedUser: IPagedUser | null = null;
  @observable user: IUser | null = null;
  @observable interUlserList: IPInternalUsers[] = [];
  @observable selectedInternalUser: IPInternalUsers | null = null;
  @observable selectedAgent: any | null = {};
  @observable isListLoading: boolean = false;
  @observable roleMasterList: IPRoleMastr[] = []
  @computed get isLoggedIn() {
    return !!this.user;
  }
  @action loadPagedUser = async (
    page: number,
    pageSize: number,
    param: IUserSearch
  ) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const user = await agent.User.getPagedUser(
        page,
        pageSize,
        param
      );
      runInAction("loading user", () => {
        this.pagedUser = user;
      });
      this.rootStore.commonStore.setLoader(false);
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
    }
  };
  @action login = async (values: IUserLogin) => {
    try {
      const user = await agent.User.login(values);
      runInAction(() => {
        this.user = user;
      });
      this.rootStore.commonStore.setToken(user);
      window.location.hash = '/home';
    } catch (error) {
      throw error;
    }
  };
  @action login_mobile = async (values: IUserLogin) => {
    try {
      this.isListLoading = true;
      this.rootStore.commonStore.setLoader(true);
      const user = await agent.User.login_mobile(values);
      runInAction(() => {
        this.isListLoading = false;
        this.rootStore.commonStore.setLoader(false);
        this.user = user;
        const isLanguage = localStorage.getItem('languageId' || null);
        if (!isLanguage) {
          this.rootStore.masterStore.actionLanguageMaster()
        }
      });
      this.rootStore.commonStore.setToken(user);
      window.location.hash = '/home';
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      throw error;
    }
  };
  @action logout = () => {
    this.rootStore.commonStore.setToken({});
    this.user = null;
    window.localStorage.removeItem("jwt");
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('expires');
    window.localStorage.removeItem('languageId');
    window.localStorage.removeItem('tokenExpiryTime');
    window.location.hash = '/login';
  };
  @action register = async (values: IUserLogin) => {
    try {
      const response = await agent.User.register(values);
      return response;
    } catch (error) {
      throw error;
    }
  };
  @action loadInternalUsers = async () => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const list = await agent.User.getInternalUserList();
      runInAction("loading internal users", () => {
        this.interUlserList = list;
      });
      this.rootStore.commonStore.setLoader(false);
    } catch (error) {
      console.log("Error is ", error);
      this.rootStore.commonStore.setLoader(false);
    }
  };
  @action InternalUsersChangePassword = async (values: any) => {
    try {
      await agent.User.InternalUsersChangePassword(values);
      runInAction("User Update", () => {
        this.rootStore.commonStore.setLoader(false);
        toast.success("Updated successfully.")
      });
    } catch (error) {
      const err: any = error
      toast.error(err.data.reason || 'Something went wrong')
      console.log("Error is ", error);
    }
  };
  @action EditInternalUsers = async (values: any) => {
    try {
      await agent.Customer.EditInternalUsers(values);
      runInAction("Edit Internal User", () => {
        this.rootStore.commonStore.setLoader(false);
        toast.success("User Updated successfully.")
      });
    } catch (error) {
      const err: any = error
      toast.error(err.data.reason || 'Something went wrong')
      console.log("Error is ", error);
    }
  };
  @action AddInternalUsers = async (values: IPInternalUsers) => {
    try {
      await agent.User.AdddInternalUsers(values);
      runInAction("User saving", () => {
        this.rootStore.commonStore.setLoader(false);
        values && values.id ? toast.success("User Updated successfully.") : toast.success("User Created successfully.");
        setTimeout(() => {
          if(values && values.userType === 'customer'){
            window.location.hash = '/customer/list';
          }else{
            window.location.hash = '/users/internalUser';
          }
          
        }, 3000);
      });
    } catch (error) {
      const err: any = error
      toast.error(err.data.reason || 'Something went wrong')
      console.log("Error is ", error);
    }
  };
  @action SelectedInternalUser = async (values: IPInternalUsers) => {
    try {
      this.selectedInternalUser = values;
    } catch (error) {
      throw error;
    }
  };
  @action addMemberAction = async (data: any) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      await agent.User._AddMember(data)
      runInAction("Add Member", () => {
        this.rootStore.commonStore.setLoader(false);
        data && data.id ? toast.success("Member Updated Successfully") : toast.success("Member saved Successfully");
        window.location.hash = '/preorder/list';
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      toast.error("Error while Add Member");
      console.log("Error : ", error);
    }
  };
  @action SetSelectedAgent = async (data: any) => {
    try {
      this.selectedAgent = data;
    } catch (error) {
    }
  };

  @action getRoleMasterAction = async () => {
    try {
      const list = await agent.User.getRoleMaster();
      runInAction("loading role master", () => {
        this.roleMasterList = list;
      });
    } catch (error) {
      console.log("Error is ", error);
    }
  };
}