import { observable, action, runInAction } from 'mobx';
import RootStore from './rootStore';
import agent from '../api/agent';
import { toast } from "react-toastify";
export default class MasterStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable bandMasterList: any[] = [];
    @observable languageMaster: any[] = [];
    @observable geoCityMaster: any[] = [];
    @observable activeLanguage: any | null = null;
    @observable settingData: any | null = null;
    @observable stateMaster: any | null = [];

    @action getBandMaster = async () => {
        try {
            this.rootStore.commonStore.setLoader(true);
            const response: any = await agent.master.GetBands();
            runInAction("loading Band master", () => {
                this.rootStore.commonStore.setLoader(false);
                this.bandMasterList = response;
            });
        } catch (error) {
            this.rootStore.commonStore.setLoader(false);
            console.log("Error is ", error);
        }
    };
    @action SaveBandMaster = async (data: any) => {
        try {
            this.rootStore.commonStore.setLoader(true);
            const response: any = await agent.master.saveBandMaster(data);
            runInAction("saving Band master", () => {
                this.rootStore.commonStore.setLoader(false);
                this.getBandMaster();
                toast.success((response && response.messages[0]) || 'Setting saved successfully');
            });
        } catch (error) {
            this.rootStore.commonStore.setLoader(false);
            toast.error('Error while saving Band')
            console.log("Error : ", error);
        }
    };

    @action actionLanguageMaster = async () => {
        try {
            this.rootStore.commonStore.setLoader(true);
            const response: any = await agent.master.GetLangulageMaster();
            runInAction("loading Band master", () => {
                this.rootStore.commonStore.setLoader(false);
                this.languageMaster = response;
            });
        } catch (error) {
            this.rootStore.commonStore.setLoader(false);
            console.log("Error is ", error);
        }
    };

    @action setActiveLanguage = (data: any) => {
        this.activeLanguage = data;
    };
    @action actionGetSetting = async (id: string, isShorting: boolean) => {
        try {
            this.rootStore.commonStore.setLoader(true);
            const response: any = await agent.master.GetSetting(id);
            runInAction("loading Setting", () => {
                this.rootStore.commonStore.setLoader(false);
                let list = JSON.parse(response.value);
                if (isShorting) {
                    list.sort((a: any, b: any) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
                }
                this.settingData = list;
            });
        } catch (error) {
            this.rootStore.commonStore.setLoader(false);
            console.log("Error is ", error);
        }
    };
    @action cityMaster = async (data: any) => {
        try {
            let temp: any = [];
            if (data && data.states && data.states.length > 0) {
                data.states.map((state: any, stateIndex: number) => {
                    temp = [...temp, ...state?.cities]
                })
            }
            temp.sort((a: any, b: any) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
            this.geoCityMaster = temp
        } catch (error) {

        }
    };
    @action actionGeoMaster = async () => {
        try {
            this.rootStore.commonStore.setLoader(true);
            const response: any = await agent.master.GetGeoMaster();
            runInAction("loading Band master", () => {
                this.rootStore.commonStore.setLoader(false);
                this.cityMaster(response)
            });
        } catch (error) {
            this.rootStore.commonStore.setLoader(false);
            console.log("Error is ", error);
        }
    };
    @action actionStateMaster = async () => {
        try {
            this.rootStore.commonStore.setLoader(true);
            const response: any = await agent.master.GetGeoMaster();
            runInAction("loading Sate master", () => {
                this.rootStore.commonStore.setLoader(false);
                this.stateMaster = response?.states
            });
        } catch (error) {
            this.rootStore.commonStore.setLoader(false);
            console.log("Error is ", error);
        }
    };
}