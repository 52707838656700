import { observable, action, runInAction } from "mobx";
import RootStore from "./rootStore";
import agent from "../api/agent";
import { toast } from "react-toastify";
import ExportService from "../../app/factory/exportService";
export default class ReportStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable report: any | null = null;
  @observable isReportLoading: boolean = false;
  @action setProductReport = async (data: any) => {
    try {
      this.report = data;
    } catch (error) {
    }
  };
  @action getProductReport = async (date: any) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const response = await agent.Report.fetchProductReport(date)
      runInAction("saving Reseller", () => {
        this.rootStore.commonStore.setLoader(false);
        this.report = response;
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      toast.error("Error while fetching report");
      console.log("Error : ", error);
    }
  };
  @action getSalesReport = async (startDate: any, endDate: any) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const response = await agent.Report.fetchSalesReport(startDate, endDate)

      runInAction("saving Reseller", () => {
        this.rootStore.commonStore.setLoader(false);
        this.report = response;
        // ExportService.exportToCSV(this.report, "report-data", ".xlsx", null, null);
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      toast.error("Error while fetching report");
      console.log("Error : ", error);
    }
  };
  @action getAgentReport = async () => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const response = await agent.Report.fetchAgentReport()
      runInAction("Getting Agent Report", () => {
        this.rootStore.commonStore.setLoader(false);
        ExportService.exportToCSV(response, "agent-report", ".xlsx", null, null);
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      toast.error("Error while fetching agent report");
      console.log("Error : ", error);
    }
  };
}
