import { observable, action, runInAction } from "mobx";
import RootStore from "./rootStore";
import agent from "../api/agent";
import { IPagedProduct } from "../models/product";
import { ICategory } from "../models/category";
import { toast } from "react-toastify";
import { IProductTag } from "../models/lookup";
export default class ProductStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable pagedProducts: IPagedProduct | null = null;
  @observable isProductSave: boolean = false;
  @observable product: any | null = null;
  @observable categories: ICategory[] = [];
  @observable indProduct: any | null = null;
  @observable indProductId: any | false = false;
  @observable imageListData: any = [];
  @observable basr64Image: any = '';
  @observable imageName: any = '';
  @observable isDefault: boolean = false;
  @observable isListLoading: boolean = false;
  @observable productBannerData: any | null = null;
  @observable selectedPrductBanner: any | null = null;
  @observable allBannersData: any | null = null;
  @observable allSettingData: any | null = null;
  @observable productTags: IProductTag[] = [];
  @observable categoryMaster: IProductTag[] = [];
  @action setBase64Image = (data: any) => {
    this.basr64Image = data;
  }

  @action setImageName = (name: any) => {
    this.imageName = name;
  }

  @action setIsDefault = (value: any) => {
    this.isDefault = value;
  }

  @action loadProducts = async (page: number, pageSize: number, param: any, loader: boolean) => {
    try {
      if (loader) {
        this.isListLoading = true;
        this.rootStore.commonStore.setLoader(true);
      }
      const products = await agent.Product.getProducts(page, pageSize, param);
      runInAction("loading Products", () => {
        if (loader) {
          this.isListLoading = false;
          this.rootStore.commonStore.setLoader(false);
        }
        this.pagedProducts = products;
      });
    } catch (error) {
      if (loader) {
        this.isListLoading = false;
        this.rootStore.commonStore.setLoader(false);
      }
    }
  };

  @action loadFullProductsList = async (page: number, pageSize: number, param: any) => {
    try {
      this.isListLoading = true;
      this.rootStore.commonStore.setLoader(true);
      const products = await agent.Product.getProducts(page, pageSize, param);
      runInAction("loading Products", () => {
        this.isListLoading = false;
        this.rootStore.commonStore.setLoader(false);
        this.pagedProducts = products;
        window.location.hash = '/product/bulk-uploads';
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      this.isListLoading = false;
    }
  };

  @action allCategories = async (languageId: boolean) => {
    try {
      const categories = await agent.Product.getCategories(languageId);
      runInAction("loading categories", () => {
        this.categories = categories;
        this.categoryMaster = categories;
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action setFilterCategoryies = async (data: any) => {
    try {
      this.categories = data;
    } catch (error) {
      console.log(error);
    }
  };
  @action addProduct = async (...args: any) => {
    try {
      const response: any = await agent.Product.addProduct(args[0]);
      this.rootStore.commonStore.setLoader(true);
      runInAction("Product saved", () => {
        this.rootStore.commonStore.setLoader(false);
        toast.success(response.messages[0]);
        if (args[1]) {
          this.rootStore.orderStore.getIndOrder(args[1], true)
        }
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      toast.error("Error while saving product");
    }
  };
  @action editProduct = async (...args: any) => {
    try {
      const response: any = await agent.Product.addProduct(args[0]);
      this.rootStore.commonStore.setLoader(true);
      runInAction("Product update", () => {
        this.rootStore.commonStore.setLoader(false);
        toast.success(response.messages[0]);
        if (args[1]) {
          this.rootStore.orderStore.getIndOrder(args[1], true)
        }
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      toast.error("Error while updating product");
    }
  };

  @action getIndProduct = async (id: any) => {
    try {
      const response: any = await agent.Product.fetchIndProduct(id);
      console.log(response);
      runInAction("get individual product", () => {
        console.log(response)
        this.setIndProduct(response);
        this.imageListData = response && response.images ? response.images : []
      });
    } catch (error) {
      toast.error("Error while fetching product");
    }
  };

  @action setIndProduct = (data: any) => {
    if (data && (!data?.productContainers || data?.productContainers.length === 0)) {
      data.productContainers = [
        {
          id: null,
          name: "",
          size: '',
          weight: '',
          unit: '',
          price: '',
          oldPrice: '',
          discount: '',
          bandId: '',
          sku: '',
          availableStartDate: new Date(),
          availableEndDate: new Date(),
          minimumStockQuantity: '',
          maxStockQuantity: '',
          stockQuantity: '',
          minimumCartQty: '',
          maximumCartQty: '',
          notifyForQuantityBelow: '',
          isPublished: false,
          isOutOfStock: false,
          isDefault: false
        },
      ]
    }
    this.product = data;
  };
  @action onProductItemSel = (data: any) => {
    this.indProductId = data && data.id ? data.id : '';
  };

  @action updateImageListData = (data: any) => {
    let existingList = JSON.parse(JSON.stringify(this.imageListData));
    if (existingList && existingList.length > 0 && data?.isDefault) {
      existingList.map((item: any) => {
        if (data.id === item.id) {
          item.isDefault = data.isDefault
        } else {
          item.isDefault = false
        }
      })
    } else {
      existingList.map((item: any) => {
        if (data.id === item.id) {
          item.isDefault = data.isDefault
        }
      })
    }
    const list = existingList;
    this.imageListData = list;
  };
  @action setImageListData = (data: any) => {
    let existingList = JSON.parse(JSON.stringify(this.imageListData));
    if (existingList && existingList.length > 0 && data?.isDefault) {
      existingList.map((item: any) => {
        item.isDefault = false
      })
    }
    const list = existingList;
    list.push(data);
    this.imageListData = list;
    this.basr64Image = '';
    this.imageName = '';
    this.isDefault = false;
  };

  @action onItemDelete = (index: any) => {
    this.imageListData.splice(index, 1);
  }

  @action onUploadPhotoData = async (id: any, data: any) => {
    try {
      const response: any = await agent.Product.uploadPhoto(id, data);
      console.log(response);
      runInAction("Product image upload", () => {
        toast.success(response.messages[0]);
      });
    } catch (error) {
      toast.error("Error while updating product image");
    }
  }

  @action BulkProductUpload = async (data: any) => {
    try {
      const response: any = await agent.Product.bulkProductUpload(data);
      console.log('BulkProductUpload', response);
      runInAction("Product saved", () => {
        toast.success(response.messages[0]);
        window.location.hash = '/product/list';
      });
    } catch (error) {
      toast.error("Error while saving product");
    }
  };
  @action loadBanners = async (
    page: number,
    pageSize: number,
    param: any
  ) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const list = await agent.Product.getPagedBanners(
        page,
        pageSize,
        param
      );
      runInAction("loading categories", () => {
        this.allBannersData = list;
      });
      this.rootStore.commonStore.setLoader(false);
    } catch (error) {
      console.log("Error is ", error);
      this.rootStore.commonStore.setLoader(false);
    }
  };

  @action GetAllProductBanner = async () => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const list = await agent.Product.FetchProductBannerList();
      runInAction("loading categories", () => {
        this.productBannerData = list;
        this.rootStore.commonStore.setLoader(false);
      });
    } catch (error) {
      console.log(error);
      this.rootStore.commonStore.setLoader(false);
    }
  };
  @action GetProductBannerDetail = async (id: any) => {
    try {
      const response = await agent.Product.FetchProductBannerDetail(id);
      runInAction("loading categories", () => {
        this.selectedPrductBanner = response;
      });
    } catch (error) {
      console.log(error);
    }
  };
  @action SetSelectedPrductBanner = async (data: any) => {
    try {
      this.selectedPrductBanner = data;
    } catch (error) {
      console.log(error);
    }
  };
  @action UploadProductBanner = async (data: any) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const response: any = await agent.Product.UploadProductBanner(data);
      runInAction("Product saved", () => {
        this.rootStore.commonStore.setLoader(false);
        toast.success(response.messages[0]);
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      toast.error("Error while saving product");
    }
  };
  @action SaveSettings = async (data: any) => {
    try {
      const response: any = await agent.Product.saveSettings(data);
      runInAction("saving settings", () => {
        this.GetAllSettings();
        toast.success(response && response.messages[0] || 'Setting saved successfully');
      });
    } catch (error) {
      toast.error("Error while saving settings");
    }
  };
  @action GetAllSettings = async () => {
    try {
      const response: any = await agent.Product.GetAllSettings();
      runInAction("loading settings", () => {
        this.allSettingData = response;
      });
    } catch (error) {
      toast.error("Error while updating product image");
    }
  }

  @action loadProductTags = async (searchTag: string) => {
    try {
      const result = await agent.Product.getProductTags(searchTag);
      runInAction(() => {
        this.productTags = result;
      });
    } catch (error) {
      toast.error("Error !");
    }
  }
  @action setProductTag = async (result: []) => {
    try {
      this.productTags = result;
    } catch (error) {
      toast.error("Error !");
    }
  }

  @action updateDefaultContainer = async (productItem: any, index: number) => {
    let existingItem = await JSON.parse(JSON.stringify(productItem));
    runInAction(() => {
      existingItem.productContainers.map((product: any, productIndex: number) => {
        product.isDefault = false;
      })
      existingItem.productContainers[index].isDefault = true;
      this.product = existingItem;
    });

  };
}
