import { observable, action, runInAction } from "mobx";
import RootStore from "./rootStore";
import agent from "../api/agent";
import { toast } from "react-toastify";
export default class BannerStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable selectedMainBanner: any | null = null;
  @observable mainAllBannersList: any | null = null;
  @action loadMainBannerList = async (
    page: number,
    pageSize: number,
    param: any
  ) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const list = await agent.Banners.getMainPagedBanners(
        page,
        pageSize,
        param
      );
      runInAction("loading Main Banners", () => {
        this.mainAllBannersList = list;
      });
      this.rootStore.commonStore.setLoader(false);
    } catch (error) {
      console.log("Error is ", error);
      this.rootStore.commonStore.setLoader(false);
    }
  };
  @action GetMainBannerDetail = async (id: any) => {
    try {
      const response = await agent.Banners.fetchMainBannerDetail(id);
      runInAction("Getting main banner detail", () => {
        this.selectedMainBanner = response;
      });
    } catch (error) {
      console.log(error);
    }
  };
  @action SetSelectedMainBanner = async (data: any) => {
    try {
      this.selectedMainBanner = data;
    } catch (error) {
      console.log(error);
    }
  };

  @action setIndBanner = async (mainData: any, updatedData: any, bannerIndex: number, imageIndex: number, action: any) => {
    let existingItem = await JSON.parse(JSON.stringify(mainData));
    debugger
    runInAction(() => {
      if (action === 1) {
        if (imageIndex > -1) {
          existingItem['banners'][bannerIndex]['images'][imageIndex] = updatedData;
        } else {
          existingItem['banners'][bannerIndex]['images'].push(updatedData)
        }
        this.selectedMainBanner = existingItem;
      } else {
        existingItem['banners'][bannerIndex]['images'].splice(imageIndex, 1);
        this.selectedMainBanner = existingItem;
      }
    });

  };


  @action UploadMainBanner = async (data: any) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const response: any = await agent.Banners.UploadMainBanner(data);
      runInAction("Product saved", () => {
        this.rootStore.commonStore.setLoader(false);
        toast.success(response.messages[0]);
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      toast.error("Error while saving product");
    }
  };
}
