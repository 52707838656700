import { observable, action, runInAction } from "mobx";
import RootStore from "./rootStore";
import agent from "../api/agent";
import { toast } from "react-toastify";
import { IDeliveryAgentMapping, IPreorderSearch } from "../models/order";
export default class OrderStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable pagedOrders: any | null = null;
  @observable isOrderSave: boolean = false;
  @observable isEditable: boolean = false;
  @observable selectedItem: any = null;
  @observable order: any = null;
  @observable selectedOrderId: any = null;
  @observable isLoading: boolean = false;
  @observable orderDetails: any | null = null;
  @observable pagedPreOrders: any | null = {
    orders: null,
    rowCount: 0
  };
  @observable exportOrderData: any | null = null;

  @action loadProducts = async (page: number, pageSize: number, param: any) => {
    try {
      this.isLoading = true;
      this.rootStore.commonStore.setLoader(true);
      const products = await agent.Order.getOrderList(page, pageSize, param);
      runInAction("loading Products", () => {
        this.isLoading = false;
        this.rootStore.commonStore.setLoader(false);
        this.pagedOrders = products;
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      this.isLoading = false;
    }
  };
  @action onIndItemEdit = (data: any) => {
    this.selectedItem = data;
    this.isEditable = true;
  };
  @action setOrderId = (id: any) => {
    this.selectedOrderId = id;
  };
  @action onEditCancel = () => {
    this.selectedItem = null;
    this.isEditable = false;
  };
  @action updateQuantity = async (...args: any) => {
    try {
      console.log("inside Edit Order qty");
      const response: any = await agent.Order.updateOrderQty(args[0]);
      console.log(response);
      runInAction(args[0]["id"] ? "Product saved" : "Product update", () => {
        toast.success(response.messages[0]);
        /* (args[1] true for fetch order detail after edit */
        if (args[1]) {
          this.getIndOrder(args[0]['id'], true)
        }
      });
    } catch (error) { }
  };
  @action updateOrderStatus = async (id: any, data: any, currentPage: any, PAGE_LIMIT: any, searchParam: any) => {
    try {
      await agent.Order.updateOrderStatus(id, data);
      runInAction('Update Delivery Date', () => {
        this.loadProducts(currentPage, PAGE_LIMIT, searchParam);
        this.onEditCancel();
      });

    } catch (error) {
      toast.error('Error on Updating Delivery status');
      console.log('Error on Updating Delivery status', error)
    }
  };
  @action updateDeliveryDate = async (data: any, currentPage: any, PAGE_LIMIT: any, searchParam: any) => {
    try {
      await agent.Order.updateOrderDeliveryDate(data);
      runInAction('Update Delivery Date', () => {
        toast.success('Delivery Date Updated');
        this.loadProducts(currentPage, PAGE_LIMIT, searchParam);
        this.onEditCancel();
      });
    } catch (error) {
      toast.error('Error on Updating Delivery Date');
      console.log('Error on Updating Delivery Date', error)
    }
  };

  @action getIndOrder = async (id: string, isFetchWalet: boolean) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const response = await agent.Order.fetchIndOrder(id);
      runInAction("getting Product data", () => {
        this.rootStore.commonStore.setLoader(false);
        this.order = response;
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
    }
  };
  @action updateRefundOrderStatus = async (id: any, data: any) => {
    try {
      console.log("inside Add Order");
      const response = await agent.Order.updateOrderStatus(id, data);
      console.log(response);
      this.getIndOrder(id, false);
    } catch (error) {
      console.log(error)
    }
  };

  @action getOrderById = async (id: any) => {
    try {
      const response = await agent.Order.fetchIndOrder(id);
      runInAction(() => {
        this.orderDetails = response;
      })
      console.log(response);
    } catch (error) {
      console.log(error)
    }
  };

  @action SetSelectedOrder = async (data: any) => {
    try {
      this.order = data;
    } catch (error) {
    }
  };
  @action exportOrderAction = async (page: number, pageSize: number, param: any) => {
    try {
      this.isLoading = true;
      this.rootStore.commonStore.setLoader(true);
      const products = await agent.Order.getOrderList(page, pageSize, param);
      runInAction("loading Products", () => {
        this.isLoading = false;
        this.rootStore.commonStore.setLoader(false);
        this.exportOrderData = products
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      this.isLoading = false;
    }
  };

  @action setexportOrderAction = async (date: any) => {
    try {
      this.exportOrderData = date
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      this.isLoading = false;
    }
  };

  @action actionAssignDeliveryAgent = async (payload: IDeliveryAgentMapping) => {
    try {
      this.isLoading = true;
      this.rootStore.commonStore.setLoader(true);
      const response: any = await agent.Order.assignDeliveryAgent(payload);
      runInAction("Assign Delivery Agent", () => {
        this.isLoading = false;
        this.rootStore.commonStore.setLoader(false);
        toast.success(response?.messages[0] || 'Successfully assigned delivery agent.');
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      this.isLoading = false;
    }
  };


  @action loadPagedPreorders = async (page: number, pageSize: number, param: IPreorderSearch) => {
    try {
      this.isLoading = true;
      this.rootStore.commonStore.setLoader(true);
      const list = await agent.Order.getPreOrderList(page, pageSize, param);
      runInAction("Loading Pre order list", () => {
        this.isLoading = false;
        this.rootStore.commonStore.setLoader(false);
        this.pagedPreOrders = list;
      });
    } catch (error) {
      const err: any = error
      runInAction("Error on loading Pre order list", () => {
        if (err && err.statusText === 'Bad Request') {
          toast.error('Something went wrong');
        } else {
          toast.error(err.statusText || 'Something went wrong');
        }
        this.isLoading = false;
        this.rootStore.commonStore.setLoader(false);
        this.pagedPreOrders = {
          orders: [],
          rowCount: 0
        };
      });
    }
  };

}
