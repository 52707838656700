import { observable, action, runInAction } from "mobx";
import RootStore from "./rootStore";
import agent from "../api/agent";
import { toast } from "react-toastify";
import { ICouponSearch, ICreateCoupon } from "../models/coupon";
export default class CouponStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable report: any | null = null;
  @observable paggedCoupon: any | null = null;
  @observable isReportLoading: boolean = false;
  @observable selectedCoupon: any | null = null;
  @action loadPagedCoupon = async (page: number, pageSize: number, param: ICouponSearch) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const response = await agent.coupon._getPagedCoupon(
        page,
        pageSize,
        param
      );
      runInAction("loading Resellers", () => {
        this.rootStore.commonStore.setLoader(false);
        this.paggedCoupon = response;
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      console.log("Error : ", error);
    }
  };
  @action CreateCouponAction = async (data: ICreateCoupon) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      await agent.coupon._createCoupon(data)
      runInAction("Save Reseller Band", () => {
        this.rootStore.commonStore.setLoader(false);
        data && data.id ? toast.success("Coupon Updated Successfully") : toast.success("Coupon Saved Successfully");
        window.location.hash='/coupon/list'
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      toast.error("Error while Save coupon");
      console.log("Error : ", error);
    }
  };
  @action SetSelectedCoupon = async (data: any) => {
    try {
      this.selectedCoupon = data;
    } catch (error) {
    }
  };
}
