import { observable, action, runInAction } from "mobx";
import RootStore from "./rootStore";
import agent from "../api/agent";
import { toast } from "react-toastify";
export default class ResellerStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable pagedrResellers: any | null = null;
  @observable reseller: any | null = null;
  @observable isResellerLoading: boolean = false;
  @observable selectedReseller: any | null = null;
  @observable selectedBrand: any | null = {};
  @observable resellerBanners: any | null = null;
  @observable band: any | null = null;
  @observable selectedBanner: any | null = {};
  @observable selectedBand: any | null = null;
  @action loadReseller = async (page: number, pageSize: number, param: any) => {
    try {
      this.isResellerLoading = true;
      const resellers = await agent.Reseller.getPagedReseller(
        page,
        pageSize,
        param
      );
      runInAction("loading Resellers", () => {
        this.isResellerLoading = false;
        this.pagedrResellers = resellers;
      });
    } catch (error) {
      console.log("Error : ", error);
    }
  };
  @action addAgentAction = async (data: any) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      if (data && data.id) {
        await agent.Reseller.editAgent(data)
      } else {
        await agent.Reseller.addAgent(data)
      }
      runInAction("saving Agent", () => {
        this.rootStore.commonStore.setLoader(false);
        data && data.id ? toast.success("Agent Successfully updated") : toast.success("Agent Successfully saved");
      });
    } catch (error) {
      const err: any = error
      this.rootStore.commonStore.setLoader(false);
      toast.error(err.data.reason || 'Error while saving Agent')
      console.log("Error : ", error);
    }
  };
  @action selectedResellerAction = (reseller: any) => {
    // this.selectedReseller = JSON.parse(JSON.stringify(reseller));
    this.selectedReseller = JSON.parse(JSON.stringify(reseller));
  };
  @action SaveResellerKyc = async (id: string, data: any) => {
    try {
      console.log("Reseller save response ", data);
      this.rootStore.commonStore.setLoader(true);
      const response = await agent.Reseller.resellerKycUpdate(id, data)
      console.log("Reseller save response ", response);
      runInAction("saving Reseller", () => {
        this.rootStore.commonStore.setLoader(false);
        toast.success("Successfully updated");
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      toast.error("Error while saving Reseller");
      console.log("Error : ", error);
    }
  };
  @action VerifyResellerKyc = async (id: string, data: any) => {
    try {
      console.log("Reseller save response ", data);
      this.rootStore.commonStore.setLoader(true);
      const response = await agent.Reseller.resellerKycVerify(id, data)
      console.log("Reseller save response ", response);
      runInAction("Vefifying Reseller", () => {
        this.rootStore.commonStore.setLoader(false);
        toast.success("Vefifying Successfully");
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      toast.error("Error while Vefifying Reseller Kyc");
      console.log("Error : ", error);
    }
  };
  @action CreateResellerBand = async (data: any) => {
    try {
      this.rootStore.commonStore.setLoader(true);
      await agent.Reseller.CreateAgentBand(data)
      runInAction("Save Reseller Band", () => {
        this.rootStore.commonStore.setLoader(false);
        data && data.id ? toast.success("Band Updated Successfully") : toast.success("Band Saved Successfully");
      });
    } catch (error) {
      this.rootStore.commonStore.setLoader(false);
      toast.error("Error while Save Reseller Band");
      console.log("Error : ", error);
    }
  };
  @action loadBanners = async () => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const list = await agent.Reseller.GetResellerBanners();
      runInAction("loading reseller banners", () => {
        this.resellerBanners = list;
      });
      this.rootStore.commonStore.setLoader(false);
    } catch (error) {
      console.log("Error is ", error);
      this.rootStore.commonStore.setLoader(false);
    }
  };
  @action CreateBanner = async (data: any) => {
    try {
      const response: any = await agent.Reseller.UploadResellerBanner(data);
      runInAction("Saving Reseller Banner", () => {
        toast.success(response.messages[0]);
      });
    } catch (error) {
      toast.error("Error while reseller Banner");
    }
  };
  @action SetSelectedBanner = async (data: any) => {
    try {
      this.selectedBanner = data;
    } catch (error) {
    }
  };
  @action loadBand = async () => {
    try {
      this.rootStore.commonStore.setLoader(true);
      const list = await agent.Reseller.GetAgentBand();
      runInAction("loading Agent Bands", () => {
        this.band = list;
      });
      this.rootStore.commonStore.setLoader(false);
    } catch (error) {
      console.log("Error is ", error);
      this.rootStore.commonStore.setLoader(false);
    }
  };
  @action SetSelectedBand = async (data: any) => {
    try {
      this.selectedBand = data;
    } catch (error) {
    }
  };
}
