import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { IPagedUser, IUser, IUserLogin, IUserSearch } from "../models/user";
import { ICategory, ICategorySearch, IPagedCategory } from "../models/category";
import { IProductSearch, IPagedProduct } from "../models/product";
import { IApiResponse } from "../models/apiResponse";
import { ICustomerSearch, IPagedCustomer } from "../models/customer";
import { IPagedReseller } from "../models/reseller";
import { config } from "../factory/config";
import { IProductTag } from "../models/lookup";
import { IHeaderStatics, IOrderData } from "../models/dashboard";
import { IPreorderSearch } from "../models/order";
//============================================
//Basic configs
//============================================
const port = config.ports;
const apiUrls = config.apiUrls;
const baseUrl = process.env.REACT_APP_API_URL;
const customerService = process.env.REACT_APP_CUSTOMER_SERVICE;
const catalogService = process.env.REACT_APP_CATALOG_SERVICE;
const orderService = process.env.REACT_APP_ORDER_SERVICE;
const axiosApiInstance = axios.create();
//============================================
//Interceptor request
//============================================
axiosApiInstance.interceptors.request.use(
  async (requestConfig: any) => {
    let isAuth = requestConfig['isAuth'] || requestConfig.url.indexOf('/eshop/') > -1;
    if (requestConfig.url.indexOf('mobile-sign-in') > -1 || requestConfig.url.indexOf('portal/signin') > -1) {
      isAuth = false
    }
    if (isAuth) {
      const token = localStorage.getItem('jwt');
      if (token) {
        requestConfig.headers['Authorization'] = `Bearer ${token}`;
      } else {
        window.location.hash = '/login';
        return
      }
    }
    if (requestConfig['languageId']) {
      const languageId = localStorage.getItem('languageId');
      requestConfig.headers['LanguageId'] = languageId;
    }
    return requestConfig;
  },
  error => {
    Promise.reject(error)
  });

//============================================
//Interceptor response
//============================================

axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  toast.dismiss();
  if (!error.response) {
    if (error.message === "Network Error") {
      toast.error("Network error - make sure API is running!");
    } else {
      toast.error(error.message || "Network error - make sure API is running!");
    }
  } else {
    const originalRequest = error.config;
    const { status} = error.response;
    if (status === 404) {
      window.location.hash = '/notfound';
      throw error.response;
    } else if (status === 400) {
      throw error.response;
    } else if (status === 500) {
      toast.error("Server error - check the terminal for more info!");
      throw error.response;
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refreshToken' || null);
      const payLoad = {
        jwtToken: localStorage.getItem('jwt'),
        refreshToken: refreshToken
      }
      const user = await User.refreshToken(payLoad);
      const minutesToAdd = 12;
      const currentDate = new Date();
      const futureDate: any = new Date(currentDate.getTime() + minutesToAdd * 60000);
      window.localStorage.setItem('refreshToken', user?.refreshToken);
      window.localStorage.setItem('expires', user?.expires.toString());
      window.localStorage.setItem('tokenExpiryTime', futureDate);
      window.localStorage.setItem('jwt', user.accessToken);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.accessToken;
      return axiosApiInstance(originalRequest);
    }

  }
  return Promise.reject(error);
});
//============================================
//Axios Request
//============================================
const responseBody = (response: AxiosResponse) => response.data;
const requests = {
  get: (url: string, optoin: {}) => axiosApiInstance.get(baseUrl + url, { ...optoin }).then(responseBody),
  post: (url: string, body: null | {}, optoin: {}) =>
    axiosApiInstance.post(baseUrl + url, body, { ...optoin }).then(responseBody),
  put: (url: string, body: {}) =>
    axiosApiInstance.put(baseUrl + url, body).then(responseBody),
  del: (url: string) => axiosApiInstance.delete(baseUrl + url).then(responseBody),
  postForm: (url: string, file: Blob) => {
    let formData = new FormData();
    formData.append("File", file);
    return axiosApiInstance.post(url, formData, {
      headers: { "Content-type": "multipart/form-data" },
    })
      .then(responseBody);
  },
};
const User = {
  login: (user: IUserLogin): Promise<IUser> =>
    requests.post(`${apiUrls.identityService.login}`, user, { isAuth: false }),
  login_mobile: (user: IUserLogin): Promise<IUser> => {
    return (
      requests.post(`${port.identityService}${apiUrls.identityService.portal_sign_in}`, user, { isAuth: false })
    )
  },
  register: (user: IUserLogin): Promise<IUser> =>
    requests.post(`sign-up`, user, { isAuth: false }),
  getPagedUser: (
    page: number,
    pageSize: number,
    param: IUserSearch
  ): Promise<IPagedUser> =>
    requests.post(
      `${port.identityService}${apiUrls.identityService.pagedUser}${page}/page-size/${pageSize}/users`,
      param ? param : {}, { isAuth: false }
    ),
  getInternalUserList: (): Promise<any> => {
    const url = apiUrls.customerService.internalUsers
    return requests.get(`${port.customerservice}${url}`, { isAuth: false })
  },
  getRoleMaster: (): Promise<any> => {
    const url = apiUrls.identityService.getrole
    return requests.get(`${port.identityService}${url}`, { isAuth: false })
  },
  AdddInternalUsers: (payload: any): Promise<any[]> => {
    return (
      requests.post(`${port.identityService}${apiUrls.identityService.addinternalUser}`, payload, { isAuth: false })
    )
  },
  InternalUsersChangePassword: (payload: any): Promise<any[]> => {
    return (
      requests.post(`${port.identityService}${apiUrls.identityService.internLUserChangePassword}`, payload, { isAuth: false })
    )
  },
  refreshToken: (token: any): Promise<IUser> => {
    return (
      requests.post(`${port.identityService}${apiUrls.identityService.refresh_tokens_use}`, token, { isAuth: false })
    )
  },
  _AddMember: (payload: any): Promise<any[]> => {
    return (requests.post(`${port.identityService}${apiUrls.identityService.addmember}`, payload, { isAuth: false }))
  },
};
const Product = {
  getProducts: (
    page: any,
    pageSize: any,
    param: IProductSearch
  ): Promise<IPagedProduct> => {
    const url = `${port.catalogService}${apiUrls.catalogService.getAllProducts.replace(`{page}`, page).replace(`{pageSize}`, pageSize)}`;
    return requests.post(url, param ? param : {}, { isAuth: false })
  },
  getCategories: (languageId: boolean): Promise<ICategory[]> =>
    requests.get(`${port.catalogService}${apiUrls.catalogService.getCagegories}`, { isAuth: false, languageId: languageId }),
  addProduct: (payload: any): Promise<ICategory[]> =>
    requests.post(`${port.catalogService}${apiUrls.catalogService.saveProduct}`, payload, { isAuth: false }),
  bulkProductUpload: (payload: any): Promise<any[]> =>
    requests.post(`${port.catalogService}${apiUrls.catalogService.bulkProductUpload}`, payload, { isAuth: false }),
  fetchIndProduct: (id: string): Promise<any> => {
    return (
      requests.get(`${port.catalogService}${apiUrls.catalogService.fetchIndProduct}${id}`, { isAuth: false, languageId: true }))
  },
  uploadPhoto: (id: string, photo: any): Promise<IApiResponse> =>
    requests.post(`${port.catalogService}${apiUrls.catalogService.saveProductImage}${id}`, photo, { isAuth: false }),
  UploadProductBanner: (payload: any): Promise<any[]> =>
    requests.post(`${port.catalogService}${apiUrls.catalogService.saveProductBanner}`, payload, { isAuth: false }),
  FetchProductBannerList: (): Promise<any[]> =>
    requests.get(`${port.catalogService}${apiUrls.catalogService.productBannerList}`, { isAuth: false }),
  FetchProductBannerDetail: (id: string): Promise<any[]> =>
    requests.get(`${port.catalogService}${apiUrls.catalogService.productBannerDetail.replace('{id}', id)}`, { isAuth: false }),
  getPagedBanners: (
    page: any,
    pageSize: any,
    param: ICategorySearch
  ): Promise<IPagedCategory> => {
    const url = `${port.catalogService}${apiUrls.catalogService.bannerListWithPaging.replace(`{page}`, page).replace(`{pagesize}`, pageSize)}`;
    return requests.post(url, param ? param : {}, { isAuth: false })
  },
  saveSettings: ((payload: any): Promise<any[]> => {
    return requests.post(`${port.catalogService}${apiUrls.catalogService.saveSettings}`, payload, { isAuth: false });
  }),
  GetAllSettings: ((): Promise<any[]> => {
    return requests.get(`${port.catalogService}${apiUrls.catalogService.getAllSettings}`, { isAuth: false });
  }),
  getProductTags: (tag: string): Promise<IProductTag[]> =>
    requests.get(`${port.catalogService}${apiUrls.catalogService.productTags}${tag}`, { isAuth: false }),
};
const Category = {
  getPagedCategories: (
    page: number,
    pageSize: number,
    param: ICategorySearch
  ): Promise<IPagedCategory> =>
    requests.post(
      `${port.catalogService}${catalogService}/category/page/${page}/page-size/${pageSize}/categories`,
      param ? param : {}, { isAuth: false }
    ),
  getCategories: (): Promise<ICategory[]> =>
    requests.get(`${port.catalogService}${apiUrls.catalogService.getCagegories}`, { isAuth: false }),
  addEditCategory: (category: ICategory): Promise<IApiResponse> =>
    requests.post(`${port.catalogService}${apiUrls.catalogService.saveCagegories}`, category, { isAuth: false }),
  addCategoryImage: (id: any, data: any): Promise<IApiResponse> => {
    return requests.post(`${port.catalogService}${apiUrls.catalogService.saveCagegoriImage}${id}`, data, { isAuth: false })
  },
  uploadPhoto: (id: string, photo: Blob): Promise<IApiResponse> =>
    requests.postForm(`/photos/${id}`, photo),
  getCategoryById: (id: string): Promise<ICategory> =>
    requests.get(`${port.catalogService}${apiUrls.catalogService.getCagegoriId}${id}`, { isAuth: false, languageId: true }),
};
const Order = {
  getOrderList: (
    page: number,
    pageSize: number,
    param: ICategorySearch
  ): Promise<ICategory[]> =>
    requests.post(
      `${port.orderService}${orderService}/page/${page}/page-size/${pageSize}/orders`,
      param ? param : {}, { isAuth: false }
    ),
  updateOrderQty: (payload: any): Promise<any[]> => {
    return (requests.post(`${port.orderService}${apiUrls.orderService.orderUpdateQty}`, payload, { isAuth: false }))
  },
  updateOrderStatus: (id: any, payload: any): Promise<any[]> => {
    return (
      requests.post(`${port.orderService}${apiUrls.orderService.orderStatusUpdate}`, payload, { isAuth: false })
    )
  },
  updateOrderDeliveryDate: (payload: any): Promise<any[]> => {
    return (
      requests.post(`${port.orderService}${apiUrls.orderService.updateDeliveryDate}`, payload, { isAuth: false })
    )
  },
  fetchIndOrder: (id: any): Promise<any> => {
    return (requests.get(`${port.orderService}${apiUrls.orderService.getOrderDetail}${id}`, { isAuth: false }))
  },
  getPreOrderList: (
    page: number,
    pageSize: number,
    param: IPreorderSearch
  ): Promise<ICategory[]> => {
    return (requests.post(
      `${port.orderService}${apiUrls.orderService.preorder_page_size}${page}/page-size/${pageSize}/search`,
      param ? param : {}, { isAuth: false }
    ))
  },
  assignDeliveryAgent: (payload: any): Promise<any[]> => {
    return (
      requests.post(`${port.orderService}${apiUrls.orderService.AssingedDeliveryAgent}`, payload, { isAuth: false })
    )
  }
};
const Customer = {
  getPagedCustomer: (
    page: number,
    pageSize: number,
    param: ICustomerSearch
  ): Promise<IPagedCustomer> =>
    requests.post(
      `${port.customerservice}${apiUrls.customerService.pagedCustomer}${page}/page-size/${pageSize}/customers`,
      param ? param : {}, { isAuth: false }
    ),
  getAllUsers: (
    name: string,
    type: string
  ): Promise<IPagedCustomer> => {
    const url = `${apiUrls.customerService.fcmusers}${name}/${type}`
    return (
      requests.get(
        `${port.customerservice}${url}`, { isAuth: false })
    )
  },
  getPagedCart: (
    page: number,
    pageSize: number,
    param: ICustomerSearch
  ): Promise<IPagedCustomer> =>
    requests.post(
      `${port.customerservice}${apiUrls.customerService.pagedCustomer}${page}/page-size/${pageSize}/carts`,
      param ? param : {}, { isAuth: false }
    ),
  getCustomerCartWishlist: (
    customerId: string,
    pageType: string,
  ): Promise<IPagedCustomer> =>
    requests.get(
      `${port.customerservice}${apiUrls.customerService.customerWishlist}${customerId}/${pageType}`, { isAuth: false }
    ),
  getPagedWishList: (
    page: number,
    pageSize: number,
    param: ICustomerSearch
  ): Promise<IPagedCustomer> =>
    requests.post(
      `${port.customerservice}${customerService}/page/${page}/page-size/${pageSize}/wishlists`,
      param ? param : {}, { isAuth: false }
    ),
  getCustomerWallet: (
    customerId: string,
  ): Promise<any> => {
    const url = apiUrls.customerService.getCustomerWallet.replace('{customerId}', customerId)
    return requests.get(
      `${port.customerservice}${url}`, { isAuth: false }
    )
  },
  getCustomerWalletHistory: (
    page: string,
    pagesize: string,
    param: ICustomerSearch
  ): Promise<any> => {
    const url = apiUrls.customerService.getCustomerWaletHistory.replace('{page}', page).replace('{pagesize}', pagesize);
    return requests.post(
      `${port.customerservice}${url}`, param ? param : {}, { isAuth: false }
    )
  },
  EditInternalUsers: (payload: any): Promise<any[]> => {
    return (
      requests.post(`${port?.customerservice}${apiUrls?.customerService?.internaluserSave}`, payload, { isAuth: false })
    )
  },
  customerWalletSave: (payload: any): Promise<ICategory[]> =>
    requests.post(`${port.customerservice}${apiUrls.customerService.walletSave}`, payload, { isAuth: false }),
  PushSend: (payload: any): Promise<ICategory[]> =>
    requests.post(`${port.customerservice}${apiUrls.customerService.pushSend}`, payload, { isAuth: false }),
  customerAgentMapping: (
    id: string,
    agentid: string
  ): Promise<IPagedCustomer> => {
    const url = `${customerService}/customer/${id}/agent/${agentid}/update`;
    return (
      requests.post(`${port.customerservice}${url}`, null, { isAuth: false })
    )
  },
  customerBandMapping: (
    payload: any
  ): Promise<IPagedCustomer> => {
    const url = apiUrls.customerService.customerBandUpdate;
    return (
      requests.post(`${port.customerservice}${url}`, payload, { isAuth: false })
    )
  },
  getAllAgents: (): Promise<IPagedCustomer> => {
    const url = apiUrls.customerService.getAllAgents;
    return (
      requests.get(`${port.customerservice}${url}`, { isAuth: false })
    )
  },
  KycVerify: (id: string, payload: any): Promise<any[]> => {
    let url = `${customerService}/customer/kyc/${id}/verify`
    return requests.post(`${port.customerservice}${url}`, payload, { isAuth: false })
  },
  CustomerDetail: (id: string): Promise<any[]> => {
    let url = `${apiUrls.customerService.customerDetail.replace('{customerId}', id)}`;
    return requests.get(`${port.customerservice}${url}`, { isAuth: true })
  },
  EditCustomer: (payload: any): Promise<any[]> => {
    return (
      requests.post(`${port?.customerservice}${apiUrls?.customerService?.customerSave}`, payload, { isAuth: false })
    )
  },
  CustomerAccountUpdate: (payload: any): Promise<any[]> => {
    return (
      requests.post(`${port?.customerservice}${apiUrls?.customerService?.customerAccountUpdate}`, payload, { isAuth: false })
    )
  },
};
const Reseller = {
  getPagedReseller: (
    page: number,
    pagesize: number,
    param: any
  ): Promise<IPagedReseller> =>
    requests.post(
      `${port.customerservice}${apiUrls.customerService.pagedAgent}${page}/page-size/${pagesize}/agents`,
      param ? param : {}, { isAuth: false }
    ),
  addAgent: (payload: any): Promise<ICategory[]> => {
    return (requests.post(`${port.identityService}${apiUrls.identityService.addAgent}`, payload, { isAuth: false }))
  },
  editAgent: (payload: any): Promise<ICategory[]> => {
    return (requests.post(`${port.customerservice}${apiUrls.customerService.updateAgent}`, payload, { isAuth: false }))
  },
  resellerKycUpdate: (id: string, payload: any): Promise<any[]> => {
    let url = apiUrls.customerService.resellerKycUpdate.replace('{id}', id)
    return requests.post(`${port.customerservice}${url}`, payload, { isAuth: false })
  },
  resellerKycVerify: (id: string, payload: any): Promise<any[]> => {
    let url = apiUrls.customerService.resellerKycVerify.replace('{id}', id)
    return requests.post(`${port.customerservice}${url}`, payload, { isAuth: false })
  },
  CreateAgentBand: (payload: any): Promise<any[]> => {
    let url = apiUrls.customerService.createAgentBand;
    return requests.post(`${port.customerservice}${url}`, payload, { isAuth: false })
  },
  GetAgentBand: (): Promise<any> => {
    const url = apiUrls.customerService.agentBand
    return requests.get(
      `${port.customerservice}${url}`, { isAuth: false }
    )
  },
  GetResellerBanners: (): Promise<any> => {
    const url = apiUrls.catalogService.getResellerbanner
    return requests.get(
      `${port.catalogService}${url}`, { isAuth: false }
    )
  },
  UploadResellerBanner: (payload: any): Promise<any[]> =>
    requests.post(`${port.catalogService}${apiUrls.customerService.createResellerBanner}`, payload, { isAuth: false }),
};
const Report = {
  fetchProductReport: (date: any): Promise<any> => {
    return (
      requests.get(`${port.orderService}${apiUrls.orderService.productReports}${date}`, { isAuth: false })
    )
  },
  fetchSalesReport: (startDate: any, endDate: any): Promise<any> => {
    return (requests.get(`${port.orderService}${apiUrls.orderService.salesReport}${startDate}/${endDate}`, { isAuth: false }))
  },
  fetchAgentReport: (): Promise<any> => {
    return (requests.get(`${port.customerservice}${apiUrls.customerService.agentReport}`, { isAuth: false }))
  }
};
const coupon = {
  _createCoupon: (payload: any): Promise<any[]> => {
    return (requests.post(`${port.orderService}${apiUrls.orderService.discountcoupan}`, payload, { isAuth: false }))
  },
  _getCoupon: (coupon: string): Promise<any[]> => {
    return (
      requests.get(`${port.orderService}${apiUrls.orderService.discountcoupan_coupan.replace('{coupan}', coupon)}`, { isAuth: false })
    )
  },
  _getPagedCoupon: (
    page: number,
    pageSize: number,
    param: any
  ): Promise<any> =>
    requests.post(
      `${port.orderService}${orderService}/page/${page}/page-size/${pageSize}/discountcoupan`,
      param ? param : {}, { isAuth: false }
    )
};

const Dashboard = {
  getWeeklyOrder: (): Promise<IOrderData[]> => {
    return (
      requests.get(`${port?.orderService}${apiUrls.orderService.lastWeekOrders}`, { isAuth: false })
    )
  },
  getStatics: (): Promise<IHeaderStatics> => {
    return (
      requests.get(`${port?.orderService}${apiUrls.orderService.topsale}`, { isAuth: false })
    )
  },
  getOrderChart: (): Promise<any> => {
    return (
      requests.get(`${port?.orderService}${apiUrls.orderService.lastWeekOrders}`, { isAuth: false })
    )
  },
  getCustomerChart: (): Promise<any> => {
    return (
      requests.get(`${port?.customerservice}${apiUrls.customerService.customersWeekReport}`, { isAuth: false })
    )
  }
};

const master = {
  saveBandMaster: ((payload: any): Promise<any[]> => {
    return requests.post(`${port.catalogService}${apiUrls.catalogService.saveBandMaster}`, payload, { isAuth: false });
  }),
  GetBands: ((): Promise<any[]> => {
    return requests.get(`${port.catalogService}${apiUrls.catalogService.getBandMaster}`, { isAuth: false });
  }),
  GetLangulageMaster: ((): Promise<any[]> => {
    return requests.get(`${port.catalogService}${apiUrls.catalogService.getLanguageMaster}`, { isAuth: true });
  }),

  GetSetting: ((id: string): Promise<any[]> => {
    return requests.get(`${port.catalogService}${apiUrls.catalogService.getSetting.replace('{key}', id)}`, { isAuth: true });
  }),
  GetGeoMaster: ((): Promise<any[]> => {
    return requests.get(`${port.catalogService}${apiUrls.catalogService.getGeoData}`, { isAuth: false });
  })
}

const Banners = {
  getMainPagedBanners: (
    page: number,
    pagesize: number,
    param: any
  ): Promise<IPagedCategory> => {
    const url = `${port.catalogService}${apiUrls.catalogService.pagedMainbannerList}${page}/page-size/${pagesize}/banners`;
    return requests.post(url, param ? param : {}, { isAuth: false })
  },
  fetchMainBannerDetail: (id: string): Promise<any[]> => {
    return (
      requests.get(`${port.catalogService}${apiUrls.catalogService.productBannerDetail.replace('{id}', id)}`, { isAuth: false })
    )
  },
  UploadMainBanner: (payload: any): Promise<any[]> => {
    return (
      requests.post(`${port.catalogService}${apiUrls.catalogService.pagedMainbannerSave}`, payload, { isAuth: false })
    )
  },
}
export default {
  User,
  Category,
  Product,
  Order,
  Customer,
  Reseller,
  Report,
  coupon,
  Dashboard,
  master,
  Banners
};
