/* eslint-disable */
import React, { lazy, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import HeaderComponent from "../../app/common/main/HeaderComponent";
import SideNavComponent from "../../app/common/main/SideNavComponent";
import FooterComponent from "../../app/common/main/FooterComponent";
import View from "../../app/common/util/wrapperView";
import LoadingComponent from "./LoadingComponent";
import { NotFound } from "./NotFound";
import { ToastContainer } from "react-toastify";
const RegisterPage = lazy(
  () => import("../../features/SystemUser/RegisterPage")
);
const DashboardPage = lazy(
  () => import("../../features/dashboard/DashboardPage")
);
const CustomerPage = lazy(() => import("../../features/customer/CustomerPage"));
const CategoryPage = lazy(() => import("../../features/category/CategoryPage"));
const AddCategoryPage = lazy(
  () => import("../../features/category/AddCategoryPage")
);
const ProductListContainer = lazy(
  () => import("../../features/product/ProductListContainer")
);
const ProductFormContainer = lazy(
  () => import("../../features/product/ProductFormContainer")
);
const ProductBulkUploadContainer = lazy(
  () => import("../../features/product/components/ProductBulkFormComponent")
);
const OrderContainer = lazy(
  () => import("../../features/order/OrderContainer")
);
const OrderEditComponent = lazy(
  () => import("../../features/order/OrderEditContainer")
);
const Invoice = lazy(() => import("../../features/order/Invoice"));
const ProductBannerListComponent = lazy(
  () => import("../../features/product/components/ProductBannerListComponent")
);
const ProductBannerComponent = lazy(
  () => import("../../features/product/components/ProductBannerComponent")
);
const SettingListComponent = lazy(
  () => import("../../features/product/components/SettingListComponent")
);
const SendPushNotificatin = lazy(
  () => import("../../features/notification/SendPushNotificatin")
);
const InternalUsersListComponent = lazy(
  () =>
    import("../../features/SystemUser/InternalUsers/InternalUsersListComponent")
);
const InternalUserFormComponent = lazy(
  () =>
    import("../../features/SystemUser/InternalUsers/InternalUserFormComponent")
);
const CouponFormComponent = lazy(
  () => import("../../features/coupons/couponFormComponent")
);

const CouponListComponent = lazy(
  () => import("../../features/coupons/CouponListComponent")
);
const RoleMasterComponent = lazy(
  () => import("../../features/Master/Roles/RoleMasterComponent")
);
const BandListComponent = lazy(
  () => import("../../features/Master/Band/BandListComponent")
);
const PreorderPageComponent = lazy(
  () => import("../../features/preorder/PreorderPageComponent")
);
const ReportPage = lazy(() => import("../../features/Reports/index"));
const AgentPage = lazy(() => import("../../features/Agent/AgentPage"));
const AgentForm = lazy(() => import("../../features/Agent/AgentForm"));
const AgentBandFormComponent = lazy(
  () => import("../../features/Agent/AgentBand/AgentBandFormComponent")
);
const AgentBandListComponent = lazy(
  () => import("../../features/Agent/AgentBand/AgentBandListComponent")
);
const Kyc = lazy(() => import("../../features/customer/Kyc"));
const MainBannerListComponent = lazy(() => import("../../features/Banners/MainBanners/List"));
const MainBannerFormComponent = lazy(() => import("../../features/Banners/MainBanners/BannerFormComponent"));
const CustomerFormComponent = lazy(() => import("../../features/customer/CustomerFormComponent"));
const CustomerEditComponent = lazy(() => import("../../features/customer/CustomerEditComponent"));
const CustomerDetailComponent = lazy(() => import("../../features/customer/CustomerDetailsComponent"));
class Main extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  componentDidMount() { }
  render() {
    return (
      <Fragment>
        <LoadingComponent />
        <ToastContainer position="top-right" />
        <View.MainLayout>
          <HeaderComponent></HeaderComponent>
          <SideNavComponent></SideNavComponent>
          <View.ContentLayout>
            <Switch>
              <Route exact path="/" component={DashboardPage} />
              <Route exact path={`/home`} component={DashboardPage} />
              <Route exact path={`/category/list`} component={CategoryPage} />
              <Route exact path={`/category/edit/:id`} component={AddCategoryPage} />
              <Route exact path={`/category/add`} component={AddCategoryPage} />
              <Route exact path={`/product/list`} component={ProductListContainer} />
              <Route exact path={`/product/add`} component={ProductFormContainer} />
              <Route exact path={`/product/edit/:id`} component={ProductFormContainer} />
              <Route exact path={`/product/bulk-uploads`} render={(props) => (
                <ProductBulkUploadContainer
                  {...props}
                  title={"Bulk Product Upload"}
                />
              )}
              />
              <Route
                exact
                path={`/product-banners`}
                render={(props) => (
                  <ProductBannerListComponent
                    {...props}
                    title={"Product Banner List"}
                  />
                )}
              />
              <Route exact path={`/product-banner-edit/:id`} render={(props) => <ProductBannerComponent {...props} />} />
              <Route exact path={`/product-banner-add/:id`} render={(props) => <ProductBannerComponent {...props} />} />
              <Route exact path={`/master/settings`} component={SettingListComponent} />
              <Route
                exact
                path={`/customer/kyc/:id`}
                component={Kyc} />
              <Route
                exact
                path="/customer/list"
                component={CustomerPage}
              />
              <Route
                exact
                path="/customer/add/:id"
                component={CustomerFormComponent}
              />
              <Route
                exact
                path="/customer/edit/:id"
                component={CustomerEditComponent}
              />
              <Route
                exact
                path="/customer/detail/:id"
                component={CustomerDetailComponent}
              />
              <Route exact path={`/users/internalUser`} render={(props) => (
                <InternalUsersListComponent {...props} title={"User List"} />
              )}
              />
              <Route
                exact
                path={`/users/internalUser/:type/:id`}
                render={(props) => <InternalUserFormComponent {...props} />}
              />
              <Route
                exact
                path={`/push/send`}
                component={SendPushNotificatin}
              />
              <Route exact path={`/orders`} component={OrderContainer} />
              <Route path={`/register-user`} component={RegisterPage} />
              <Route path={`/editOrder`} component={OrderEditComponent} />
              <Route path={`/invoice/:id`} component={Invoice} />
              <Route
                exact
                path={`/coupon/list`}
                component={CouponListComponent}
              />
              <Route
                exact
                path={`/coupon/form`}
                component={CouponFormComponent}
              />
              <Route
                exact
                path={`/coupon/form/:id`}
                component={CouponFormComponent}
              />
              {/* AGent*/}
              <Route exact path={`/agent/list`} component={AgentPage} />
              <Route exact path={`/agent/form/:id`} component={AgentForm} />
              <Route
                exact
                path={`/agent/band/list`}
                render={(props) => (
                  <AgentBandListComponent
                    {...props}
                    title={"Agent Band List"}
                  />
                )}
              />
              <Route
                exact
                path={`/agent/band/:id`}
                component={AgentBandFormComponent}
              />
              {/* master */}
              <Route
                exact
                path={`/master/roles`}
                component={RoleMasterComponent}
              />
              <Route
                exact
                path={`/master/band`}
                component={BandListComponent}
              />
              {/* Preorder */}
              <Route
                exact
                path={`/preorder/list`}
                component={PreorderPageComponent}
              />
              {/* Banners */}
              <Route
                exact
                path={`/banners/main/list`}
                component={MainBannerListComponent}
              />
              <Route
                exact
                path={`/banners/main/:action/:id`}
                component={MainBannerFormComponent}
              />
              {/* Reports */}
              <Route path={`/reports`} component={ReportPage} />
              <Route component={NotFound} />
            </Switch>
          </View.ContentLayout>
          <FooterComponent></FooterComponent>
        </View.MainLayout>
      </Fragment>
    );
  }
}
export default Main;
