import { createContext } from 'react';
import { configure } from 'mobx';
import CommonStore from './commonStore';
import UserStore from './userStore';
import CategoryStore from './categoryStore';
import ProductStore from './productStore';
import OrderStore from './orderStore';
import CustomerStore from './customerStore';
import ResellerStore from './resellerStore';
import ReportStore from './reportStore';
import CouponStore from './couponStore';
import DashboardStore from './dashboardStore';
import MasterStore from './masterStore';
import BannerStore from './bannerStore';

configure({ enforceActions: 'always' });

export default class RootStore {

    commonStore: CommonStore;
    userStore: UserStore;
    categoryStore: CategoryStore;
    productStore: ProductStore;
    orderStore: OrderStore;
    customerStore: CustomerStore;
    resellerStore: ResellerStore;
    reportStore: ReportStore;
    couponStore: CouponStore;
    dashboardStore: DashboardStore;
    masterStore: MasterStore;
    bannerStore: BannerStore;

    constructor() {
        this.commonStore = new CommonStore(this);
        this.userStore = new UserStore(this);
        this.categoryStore = new CategoryStore(this);
        this.productStore = new ProductStore(this);
        this.orderStore = new OrderStore(this)
        this.customerStore = new CustomerStore(this);
        this.resellerStore = new ResellerStore(this);
        this.reportStore = new ReportStore(this);
        this.couponStore = new CouponStore(this);
        this.dashboardStore = new DashboardStore(this);
        this.masterStore = new MasterStore(this);
        this.bannerStore = new BannerStore(this);
    }
}

export const RootStoreContext = createContext<RootStore>(new RootStore());