import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
export const ExportService = {
  createJson: (data: any, keyToIgnore: any, nestedKeys: any) => {
    let myData: any = [];
    data.map((product: any, index: number) => {
      let keyToIgnoreArr = keyToIgnore || [];
      let nestedKeysArr = nestedKeys || [];
      let item: any = {};
      for (let key in product) {
        if (keyToIgnoreArr.indexOf(key) === -1) {
          if (nestedKeysArr.indexOf(key) > -1) {
            if (Array.isArray(product[key])) {
              if (product[key] && product[key].length > 0) {
                for (let i = 0; i < product[key].length; i++) {
                  let data = product[key][i];
                  for (let key2 in data) {
                    let keyName = key + " " + i + " " + key2;
                    item[keyName] = data[key2];
                  }
                }
              }
            } else if (typeof product[key] === "object") {
              for (let key2 in product[key]) {
                let keyName = key + " " + key2;
                item[keyName] = product[key][key2];
              }
            }
          } else {
            item[key] = product[key];
          }
        }
      }
      myData.push(item);
    });
    return myData;
  },
  exportToCSV: (
    csvData: any,
    fileName: string,
    fileExtension: string,
    keyToIgnore: any,
    nestedKeys: any
  ) => {
    let list = JSON.parse(JSON.stringify(csvData));
    if (list && list.length > 0) {
      let data =list;
      if (keyToIgnore || nestedKeys) {
        data = ExportService.createJson(
          JSON.parse(JSON.stringify(csvData)),
          keyToIgnore,
          nestedKeys
        );
      }
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const exportdata = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(exportdata, fileName + fileExtension);
      toast.success(fileName + fileExtension + "saved successfully");
    }
  },
};
export default ExportService;
